@import './vendor/react-slick';
@import './vendor/diff2html.min';

.fontFamilyWorkSans {
    font-family: "Work Sans", sans-serif !important;
}

.cursor-pointer {
    cursor: pointer;
}

.align-self-end {
    align-self: flex-end;
}

.button-loader {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.bold {
    font-weight: bold !important;
}

/* hack to hide the browsers' password reveal eye-icon */
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.rotation-loading {
    -webkit-animation: rotation 2s infinite linear;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-359deg);
        transform: rotate(-359deg);
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(-359deg);
        transform: rotate(-359deg);
    }
}